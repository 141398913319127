import React from "react";
import NavBar from "../Components/NavBar";
import VideoHome from "../Containers/VideoHome";
import video from "../Assets/video_web.mp4";
import video_phone from "../Assets/video_phone.mp4";
import Footer from "../Containers/Footer";
import Switcher from "../Components/Switcher";

const Tutorial = () => {
  return (
    <>
      <div className="md:block absolute hidden top-8 md:right-2 2xl:right-8 3xl:right-12 z-20">
        <Switcher />
      </div>
      <div className="flex flex-col items-center justify-center w-full h-full m-auto  dark:bg-slate-800">
        <div className="flex bg-transparent items-center px-[20px] z-100">
          <NavBar />
        </div>
        <div className="w-full hidden md:flex items-center justify-center mt-16">
          <VideoHome video={video} />
        </div>
        <div className="w-full md:hidden flex items-center justify-center mt-16">
          <VideoHome video={video_phone} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Tutorial;
