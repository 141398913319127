/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import NavBar from "../Components/NavBar";
import HeroSection from "../Containers/HeroSection";
import Features from "../Containers/Features";
import Marketing from "../Containers/Marketing";
import ChatCoach from "../Containers/ChatCoach";
import FadedBack from "../Components/FadedBack";
import FAQ from "../Containers/FAQ";
import Waitlist from "../Containers/Waitlist";
import Footer from "../Containers/Footer";
import ReviewContainer from "../Containers/ReviewContainer";
import Aos from "aos";
import "aos/dist/aos.css";
import Features1 from "../Containers/Features1";
import { useDispatch } from "react-redux";
import { toggleAbout } from "../Store/aboutSlice/about";
import { viewContent } from "../Data/ticktokapis";

// import axios from "axios";
import Modes from "../Containers/Modes";
import Switcher from "../Components/Switcher";
Aos.init();

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleAbout(false));
    viewContent();
    window.scrollTo(0, 0);
  }, [dispatch]);

  // const getUserInformation = async (token) => {
  //   const userRes = await axios({
  //     method: "get",
  //     url: "https://admin.chatcoach.io/api/userinformation",
  //     headers: {
  //       Authorization: `Bearer ${token}`
  //     }
  //   });

  //   const { data } = userRes;
  //   return data;
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     getUserInformation(localStorage.getItem("token")).then((res) => {
  //       if (res.subscription && res.userhassub !== 0) {
  //         const dateExpiry = new Date(res.subscription.expiry_date);
  //         if (Date.now() < dateExpiry) {
  //           localStorage.setItem("subscribed", true);
  //           localStorage.setItem("plan", res.subscription.plan_id);
  //           return;
  //         } else {
  //           localStorage.removeItem("plan");
  //           localStorage.removeItem("subscribed");
  //           navigate("/subscription");
  //         }
  //       } else {
  //         localStorage.removeItem("plan");
  //         localStorage.removeItem("subscribed");
  //         navigate("/subscription");
  //       }
  //     });
  //   } else {
  //     return;
  //   }
  // }, []);

  return (
    <>
      <div className="md:block absolute hidden top-8 md:right-2 2xl:right-8 3xl:right-12 z-20">
        <Switcher />
      </div>
      <div
        className={` transition-all duration-300 ease-in-out dark:bg-slate-800`}
      >
        <div className="flex bg-transparent items-center px-[20px] z-100">
          <NavBar />
        </div>
        <div class="container-scroll " id="container-scroll">
          <div class="section-snap md:h-screen relative">
            <HeroSection />
            <FadedBack />
          </div>

          <div class="section-snap w-full flex items-center justify-center mb-16 md:mb-24">
            <Features />
          </div>
          <div class="section-snap w-full flex items-center justify-center mb-0 md:mb-24 lg:mb-0 ">
            <Features1 />
          </div>
          <div style={{ scrollSnapAlign: "start" }}>
            <div
              className="py-8 w-full flex flex-col items-center justify-center bg-[#edf1f6ff] dark:bg-slate-800 next xl:mt-[100px] mt-0"
              id="features"
            >
              <Marketing />
              <Modes />
            </div>
            <ChatCoach />
            <FAQ />
            <ReviewContainer />
            <Waitlist />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
